import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import partners from '~/lib/configs/partners.json';

const PartnerSection: React.FC = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <section className="relative overflow-hidden py-16 text-white flex flex-col items-center justify-center min-h-screen gap-6 mb-10">
      <div className="absolute inset-0 overflow-hidden">
        {Array.from({ length: 3 }).map((_, i) => (
          <div
            key={i}
            className="absolute rounded-full mix-blend-screen filter blur-xl opacity-30 animate-blob bg-primary/80"
            style={{
              width: "30rem",
              height: "30rem",
              animationDelay: `${i * 2}s`,
              left: `${20 + i * 20}%`,
              top: `${20 + i * 15}%`,
            }}
          ></div>
        ))}
      </div>
      {visible && (
        <motion.h2
          className="text-5xl font-bold mb-16 spaced-text text-center mt-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Our Partners
        </motion.h2>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto px-4">
        {partners.map((partner, index) => (
          <motion.div
            key={partner.name}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="partner-card rounded-xl h-[400px]"
          >
            <img src={partner.bgImage} alt="" className="bg-image" />

            <div className="content flex flex-col items-center justify-center h-full p-8 bg-black/50">
              <div className="mb-6 transform transition-transform duration-300 hover:scale-110">
                <img
                  src={partner.pfp}
                  alt={partner.name}
                  className="w-32 h-32 rounded-full object-cover border-4 border-white/30 shadow-xl"
                />
              </div>

              <h3 className="text-2xl font-bold mb-3 spaced-text text-center">
                {partner.name}
              </h3>

              <p className="text-center text-white/90 leading-relaxed">
                {partner.description}
              </p>

              <div className="mt-10 rounded-md text-center justify-center items-center">
                <a
                  href={partner.href}
                  className="rounded-md bg-gradient-to-l from-primary/80 to-background-blend p-2 flex flex-row justify-center items-center gap-3 ease-in-out duration-[0.5s] GingerBread"
                >
                  <i className="fas fa-link bg-background-blend p-2 rounded-md"></i>
                  <div>View Partner</div>
                </a>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default PartnerSection;
