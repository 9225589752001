import config from '~/lib/configs/config.json';
export const Discord = () => {

  return (
    <section
      className="border-y border-y-primary/40 bg-primary/10 py-12 md:py-24 flex items-center justify-center mb-5"
      style={{
        backgroundImage: "radial-gradient(var(--primary) 1px, transparent 1px)",
        backgroundSize: "16px 16px",
      }}
    >
      <div className="relative w-full mx-3 max-w-5xl bg-black rounded-xl p-6 sm:p-8 md:p-10 shadow-lg text-white border-primary border-[1px]">
        <div className="relative flex flex-col items-center md:flex-row md:items-center gap-6 sm:gap-8 md:gap-12">
          <div className="flex-shrink-0 w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 md:order-2">
            <img
              src="/discord.webp"
              alt="Discord"
              className="object-contain w-full h-full"
            />
          </div>

          <div className="flex-1 text-center md:text-left space-y-3 sm:space-y-4">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold">
              Join Our Discord Community
            </h2>
            <p className="text-gray-300 text-sm sm:text-base">
              Be a part of a community with over xAmount members and get the
              latest updates, support, and more.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="mt-6 inline-flex items-center text-sm gap-2 justify-center duration-300 font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-primary/40 text-primary bg-gradient-to-r from-primary/5 to-primary/20 hover:bg-primary/10 h-11 rounded-md px-5"
              href={config.Discord}
            >
              Join Discord
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="ml-2 inline-block size-4"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 12h14"></path>
                <path d="m12 5 7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
